import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import RichText from "../components/RichText/RichText";

import {
  title,
  post,
  bylineStyles
} from "./blog-post.module.scss";

const BlogPost = ({ data }) => {
  const { blogPost } = data;
  const { postTitle, blogEntry, byline } = blogPost;

  return (
    <Layout>
      <article className="grid-wrapper">
        <h1 className={title}>{postTitle}</h1>
        <p className={bylineStyles}>{ byline }</p>
        <RichText className={post} richText={blogEntry} />
      </article>
    </Layout>
  );
};

export default BlogPost;

export const queryBlog = graphql`
  query ($id: String!) {
    blogPost: contentfulBlogPost(id: { eq: $id }) {
      postTitle
      blogEntry {
        raw
      }
      byline
    }
  }
`;

export { Head } from "../components/Head/Head";
